import crypto from "crypto";
import { useMemo } from "react";

export const CDN_DOMAIN = "https://cdn.eu.trustpayments.com/js/latest/st.js";
export const JWTusername = process.env.REACT_APP_TRUST_PAYMENT_JWT_USERNAME;
export const JWTsecretKey = process.env.REACT_APP_TRUST_PAYMENT_JWT_SECRETKEY;

const getBase64URLEncodedString = (data: any) => {
  const jsonString = JSON.stringify(data);

  //Encode the string in Base64
  const base64String = Buffer.from(jsonString).toString("base64");

  //Convert Base64 to Base64URL
  const base64URLEncodedString = base64String
    .replace(/\+/g, "-")
    .replace(/\//g, "_")
    .replace(/=/g, "");

  return base64URLEncodedString;
};

export const getJWTtoken = (
  header: any,
  payload: any,
  secret: string
): string => {
  const encodedHeader = getBase64URLEncodedString(header);
  const encodedPayload = getBase64URLEncodedString(payload);
  const encodedToken = `${encodedHeader}.${encodedPayload}`;

  // Use HMAC SHA256 to hash the concatenated string with the secret key
  const signature = crypto
    .createHmac("sha256", secret)
    .update(encodedToken)
    .digest("base64");

  const formatSignature = signature
    .replace(/\+/g, "-")
    .replace(/\//g, "_")
    .replace(/=/g, "");

  // Return the JWT token consisting of encoded header, payload, and signature
  return `${encodedHeader}.${encodedPayload}.${formatSignature}`;
};

// Mapping of first letter of postal codes to province data
const postalCodeMap: Record<string, { abbr: string; full: string }> = {
  A: { abbr: "NL", full: "Newfoundland and Labrador" },
  B: { abbr: "NS", full: "Nova Scotia" },
  C: { abbr: "PE", full: "Prince Edward Island" },
  E: { abbr: "NB", full: "New Brunswick" },
  G: { abbr: "QC", full: "Eastern Quebec" },
  H: { abbr: "QC", full: "Metropolitan Montreal" },
  J: { abbr: "QC", full: "Western Quebec" },
  K: { abbr: "ON", full: "Eastern Ontario" },
  L: { abbr: "ON", full: "Central Ontario" },
  M: { abbr: "ON", full: "Metropolitan Toronto" },
  N: { abbr: "ON", full: "Southwestern Ontario" },
  P: { abbr: "ON", full: "Northern Ontario" },
  R: { abbr: "MB", full: "Manitoba" },
  S: { abbr: "SK", full: "Saskatchewan" },
  T: { abbr: "AB", full: "Alberta" },
  V: { abbr: "BC", full: "British Columbia" },
  X: { abbr: "NT/NU", full: "Northwest Territories/Nunavut" },
  Y: { abbr: "YT", full: "Yukon" },
};

export const getProvinceFromPostalCode = (
  postalCode: string,
  type: "abbr" | "full"
): string => {
  if (!postalCode || typeof postalCode !== "string") return "";

  const firstLetter = postalCode.trim().toUpperCase().charAt(0);
  const provinceData = postalCodeMap[firstLetter];

  if (!provinceData) return ""; // Return null if no matching province

  return provinceData[type];
};

const useBillingDetails = (user: any) => {
  const billingDetails = useMemo(() => {
    const billingemail = user?.username || "";
    const billingfirstname = user?.profile?.firstName || "";
    const billinglastname = user?.profile?.lastName || "";
    const billingdob =
      user?.profile?.day && user?.profile?.month && user?.profile?.year
        ? `${user.profile.year}-${user.profile.month}-${user.profile.day}`
        : ""; // YYYY-MM-DD
    const billingpremise = user?.profile?.address1 || "";
    const billingtown = user?.profile?.city || "";
    const billingpostcode = user?.profile?.zip || "";
    const billingcountryiso2a = user?.profile?.location_country || "";
    const billingstreet = user?.profile?.address1 || "";
    const billingcounty =
      user?.profile?.location_country?.toLowerCase() === "ca"
        ? getProvinceFromPostalCode(user?.profile?.zip, "abbr")
        : "";
    const customeraccountnumber = user?.referral?.code
      ? user.referral.code.slice(3) // Remove "SBR"
      : "";

    return {
      billingemail,
      billingfirstname,
      billinglastname,
      billingdob,
      billingpremise,
      billingtown,
      billingpostcode,
      billingcountryiso2a,
      billingstreet,
      billingcounty,
      customeraccountnumber,
    };
  }, [user]);

  return billingDetails;
};

export default useBillingDetails;
