//const {exchangeRate, payinCurrency, payoutCurrency, } = transferState

import { useQuery } from "react-query";
import { LOADING, TRANSFER } from "redux/actionTypes";
import { getServiceRate, toastAction } from "redux/actions/actions";
import store from "redux/store";
import endpoints from "util/endpoints";
import http, { getRequest } from "util/http";
import env from "../../../../../env";
import { parseEndpointParameters } from "../../../../../util/util";
import {
  formatAmount,
  getAppValueDataByName,
  transferMethodsInWords,
} from "../../utils/reuseableUtils";
import { userAppValues } from "../../utils/useAppValues";

//trnafer method to index
const transferMethodIds: any = {
  mobile_money: "1",
  bank_transfer: "2",
  cash_pickup: "3",
};

export const getTransactionQuoteRequest = async (
  method: string,
  callback: Function,
  onErrorCallback: Function
) => {
  const userId = store.getState().auth.user?.id;
  const transfer = store.getState().transfer;
  const {
    payinCurrency,
    payoutCurrency,
    allowOperatorFee,
    payinActualValue,
    promoCode,
    payoutActualValue,
  } = transfer;
  const { PayoutCountries, PayinCountries } = userAppValues();

  const payload: { base: string; target: string; meta?: any } = {
    base: payinCurrency,
    target: payoutCurrency,
    meta: { userId },
  };
  try {
    const { data } = await http.post("/quote", payload);

    const destinationCountryCode = PayoutCountries?.find(
      (country) => country.currency === payoutCurrency
    )?.countryCode;
    const originCountryCode = PayinCountries.find(
      (country) => country.currency === payinCurrency
    )?.countryCode;
    const exchangeRateQuoteId = data?.data?.id;

    const payloadForFinalQoute = {
      transferMethodId: transferMethodIds[method || ""],
      originCurrency: payinCurrency,
      originAmount: String(payinActualValue),
      destinationCurrency: payoutCurrency,
      includeOperatorFee:
        Number(getServiceRate(method).operatorFee) > 0
          ? allowOperatorFee
          : false, // If operator fee is 0, do not include it
      exchangeRateQuoteId: exchangeRateQuoteId,
      promoCode: promoCode,
      destinationCountryCode,
      originCountryCode,
      calculatorDestinationAmount: payoutActualValue,
    };

    await http.post("/transfer/quote", payloadForFinalQoute).then((res) => {
      store.dispatch({
        type: LOADING,
        payload: "IInitializing your transfer...",
      });
      if (res.data.status === "200") {
        store.dispatch({ type: LOADING, payload: false });
        return callback(res.data.data);
      } else {
        store.dispatch({ type: LOADING, payload: false });
        onErrorCallback();
        toastAction({
          show: true,
          type: "error",
          timeout: 15000,
          message:
            res.data.error.message || "An error occurred. Please try again.",
        });
      }
    });
  } catch (error: any) {
    store.dispatch({ type: LOADING, payload: false });
    onErrorCallback();
    toastAction({
      show: true,
      type: "error",
      timeout: 15000,
      message: "An error occurred. Please try again.",
    });
  }
};

export const isWithinPaymentLimit = (transfer: any) => {
  const {
    transferMethod,
    payinActualValue,
    payoutActualValue,
    payinCurrency,
    payoutCurrency,
    allowOperatorFee,
  } = transfer;

  if (payinActualValue === 0) {
    return "";
  }
  if (payinActualValue < 5) {
    return `You can't send less than 5 ${payinCurrency}`;
  }
  if (
    transferMethod === "mobile_money" &&
    payoutActualValue >= 500000 &&
    allowOperatorFee
  ) {
    // auto switch off operator fee threshold is reached for XAF
    store.dispatch({
      type: TRANSFER,
      payload: {
        ...transfer,
        allowOperatorFee: false,
      },
    });
  }

  if (transferMethod === "mobile_money" && payoutActualValue > 500000) {
    return `The maximum transferrable amount inclusive of Mobile Operator Transfer Fees for mobile money is 500,000 ${payoutCurrency}`;
  }

  if (
    (transferMethod === "bank_transfer" || transferMethod === "cash_pickup") &&
    payinActualValue > 20000
  ) {
    return `The maximum transferrable amount for ${transferMethod.replace(
      "_",
      " "
    )} is 20,000 ${payinCurrency}`;
  }

  return "";
};

export function refinePromoErrorMessage(message: any, code: string) {
  const errorMessages: any = [
    {
      value: "Promo not found. No resource found",
      refined: "Invalid promo code.",
    },
    {
      value: "Promo not found. Invalid user",
      refined: `Voucher code "${code}" has already been redeemed`,
    },
  ];

  const refinedMessage = errorMessages.find(
    (msg: any) => msg.value === message
  );
  return refinedMessage ? refinedMessage.refined : message;
}

export const useExchangeRate = (
  base: string,
  target: string,
  enabled: boolean
) => {
  const transfer = store.getState().transfer;
  const endpoint = `/exchange/${base}/${target}`;
  return useQuery(
    endpoint,
    () => getRequest(endpoint, "Failed to fetch exchange rate"),
    {
      enabled,
      onSuccess: (data) => {
        store.dispatch({
          type: TRANSFER,
          payload: {
            ...transfer,
            exchangeRate: handleValueIsNaN(data?.rate),
            conversionRate: data,
          },
        });
      },
    }
  );
};

export const getPromos = async (endpoint: string, payinCurrency: string) => {
  const { payinActualValue } = store.getState().transfer;

  try {
    const result = await http.get(endpoint, {
      headers: { "X-SERVICE-PROVIDER": env.X_SERVICE_PROVIDER },
    });

    if (result?.data?.status === 200 || result?.data?.status === "200") {
      const promo = result?.data?.data;
      return promo;
    } else {
      throw new Error(
        result?.data?.error?.message ||
          `Something went wrong. Please try again.`
      );
    }
  } catch (error: any) {
    // console.log(error);
    throw error; // Throw the error to be caught by React Query
  }
};

export const useGetPromo = (
  code: string,
  payinCurrency: string,
  payinActualValue: number,
  enabled: boolean,
  onSuccess: Function
) => {
  const endpoint = parseEndpointParameters(endpoints.PROMO, code);
  return useQuery(endpoint, () => getPromos(endpoint, payinCurrency), {
    enabled,
    retry: false,
    onSuccess: (data) => {
      onSuccess({ data });
    },
  });
};

export const getPromoMessages = (promo: any) => {
  const { payinActualValue, payinCurrency, payoutCurrency } =
    store.getState().transfer;

  let successMessage = "";
  let errMessage = "";

  const {
    percentage,
    discountAmount,
    currenciesValid,
    baseCurrency,
    targetCurrency,
    minimumSpend,
    maximumSpend,
  } = promo?.settings || {};

  const isBelowSpendLimit = Number(payinActualValue) < Number(minimumSpend);
  const isAboveSpendLimit = Number(payinActualValue) > Number(maximumSpend);

  const isAppliedToUserCurrencies =
    payinCurrency === baseCurrency && payoutCurrency === targetCurrency;

  if (isBelowSpendLimit || isAboveSpendLimit) {
    errMessage = `Transfer between ${minimumSpend} ${payinCurrency} and  ${maximumSpend} ${payoutCurrency} to use this promo.`;
  } else if (
    Boolean(currenciesValid) &&
    currenciesValid !== "ALL" &&
    currenciesValid !== payinCurrency
  ) {
    errMessage = `${discountAmount} ${payinCurrency} Discount, valid for ${currenciesValid} currencies`;
  } else if (!isAppliedToUserCurrencies && promo?.type === "FIXED_RATE") {
    errMessage = `Fixed Exchange rate, valid for ${baseCurrency} to ${targetCurrency}`;
  } else {
    switch (promo?.type) {
      case "PERCENTAGE":
        successMessage = `${percentage}% Discount, valid for ${currenciesValid} currencies`;
        break;
      case "FIXED_AMOUNT":
        successMessage = `${discountAmount} ${payinCurrency} Discount, valid for ${currenciesValid} currencies`;
        break;
      case "FIXED_RATE":
        successMessage = `Fixed Exchange rate, valid for ${baseCurrency} to ${targetCurrency}`;
        break;
      case "FREE_OPERATOR_FEE":
        successMessage = "Free Operator Fee";
        break;
      default:
        successMessage = "";
        break;
    }
  }

  return { successMessage, errMessage };
};

type TPromotype =
  | "FIXED_RATE"
  | "FIXED_AMOUNT"
  | "PERCENTAGE"
  | "FREE_OPERATOR_FEE";

export const promoCalculator = (promo: any) => {
  const transfer = store.getState().transfer;
  const {
    payinActualValue: payinInput,
    exchangeRate: derivedRate,
    payinCurrency,
    payoutCurrency,
    payoutActualValue,
    transferMethod,
  } = store.getState().transfer;
  // const derivedRate = exchangeRateData?.find((rate: any)=> rate.base === payinCurrency && rate.target === payoutCurrency)?.rate

  const { type, code } = promo || {};
  const {
    maximumSpend,
    minimumSpend,
    discountAmount: value,
    baseCurrency,
    targetCurrency,
    rate: promoRate,
    currenciesValid,
    percentage,
  } = promo?.settings || {};

  let exchangeRate = derivedRate ? Number(derivedRate) : 0;
  let discountAmount = 0;
  let percentMultiplyer = 1;
  let freeOperatorFee = false;
  let promoType: TPromotype = "FIXED_AMOUNT";

  //TODO: include implementation for other promo types
  const isInPromoAmountRange =
    promo &&
    Number(payinInput) >= Number(minimumSpend) &&
    Number(payinInput) <= Number(maximumSpend);
  if (isInPromoAmountRange && type === "FIXED_RATE") {
    exchangeRate = Number(value);
    promoType = "FIXED_RATE";
    // if payinCurrency === settings.baseCurrency && payoutCurrency === settings.targetCurrency
    // dispatch promoDiscountValue to be equivalent payinDiffrence in old and new rate

    if (payinCurrency === baseCurrency && payoutCurrency === targetCurrency) {
      const payinDiffrence =
        payoutActualValue / Number(derivedRate) -
        payoutActualValue / handleValueIsNaN(promoRate);
      const equivalentDiscount = Math.abs(payinDiffrence); // Take the absolute value to remove the sign

      const newPayout = calculatePayAmount(payinInput, promoRate, false);
      const promoServiceRate = getServiceRate(
        transferMethod,
        newPayout,
        promoRate
      );

      const promoOperatorFee = promoServiceRate.isInOriginCurrency
        ? promoServiceRate.operatorFeeInOriginCurrency
        : promoServiceRate.operatorFee;

      store.dispatch({
        type: TRANSFER,
        payload: {
          ...transfer,
          promoDiscountValue: equivalentDiscount.toFixed(2),
          promoType: promoType,
          promoRate: handleValueIsNaN(promoRate).toFixed(2),
          promoCode: code,
          fixedRateOperatorFee: handleValueIsNaN(promoOperatorFee),
        },
      });
    }
  }
  if (isInPromoAmountRange && type === "FIXED_AMOUNT") {
    discountAmount =
      currenciesValid === "ALL"
        ? Number(value)
        : currenciesValid === payinCurrency
        ? Number(value)
        : 0;
    promoType = "FIXED_AMOUNT";
    //Dispatch discount amount

    store.dispatch({
      type: TRANSFER,
      payload: {
        ...transfer,
        promoDiscountValue: discountAmount?.toFixed(2),
        promoType: promoType,
        promoCode: code,
      },
    });
  }
  if (isInPromoAmountRange && type === "PERCENTAGE") {
    // settings.percentage
    percentMultiplyer = (100 - Number(percentage)) / 100;
    discountAmount = payinInput * (1 - percentMultiplyer);
    promoType = "PERCENTAGE";

    store.dispatch({
      type: TRANSFER,
      payload: {
        ...transfer,
        promoDiscountValue: discountAmount?.toFixed(2),
        promoType: promoType,
        promoCode: code,
      },
    });
  }
  if (isInPromoAmountRange && type === "FREE_OPERATOR_FEE") {
    freeOperatorFee = true;
    promoType = "FREE_OPERATOR_FEE";

    store.dispatch({
      type: TRANSFER,
      payload: {
        ...transfer,
        promoFreeOperatorFee: freeOperatorFee,
        promoType: promoType,
        promoCode: code,
        allowOperatorFee: true, //Allow operator fee to use FREE OPERATOR FEE promo
      },
    });
  }
};

// Function to handle NaN, defaulting to 0 if invalid
export const handleValueIsNaN = (value?: number | string) =>
  isNaN(Number(value)) ? 0 : Number(value);

export const formatNumberWithCommas = (value: string | number): string => {
  const valueToString = String(value);

  const [integerPart, fractionalPart] = valueToString.split(".");
  const formattedIntegerPart = integerPart.replace(
    /\B(?=(\d{3})+(?!\d))/g,
    ","
  );

  return fractionalPart !== undefined
    ? `${formattedIntegerPart}.${fractionalPart}`
    : formattedIntegerPart;
};

export const removeLeadingZeros = (value: string): string => {
  const [integerPart, fractionalPart] = value.split(".");
  const cleanedIntegerPart = integerPart.replace(/^0+(?!$)/, "");
  return fractionalPart !== undefined
    ? `${cleanedIntegerPart}.${fractionalPart}`
    : cleanedIntegerPart;
};

export const checkAmountValidation = (
  serviceRate: any,
  isPayinInputActive: boolean
) => {
  const transfer = store.getState().transfer;
  const {
    allowOperatorFee,
    payinCurrency,
    payoutCurrency,
    payinActualValue,
    payoutActualValue,
    transferMethod,
  } = transfer || {};

  const {
    operatorFee = 0,
    transferLimitMax = 0,
    isInOriginCurrency = false,
  } = serviceRate || {};

  const formattedTransferLimitMax = handleValueIsNaN(transferLimitMax);
  const IsBelowMinPayinValue =
    Number(payinActualValue) > 0 && Number(payinActualValue) < 5;

  const isMobileMoney =
    transferMethodsInWords[transferMethod] === "mobile_money";

  // Function to generate an error message based on transferMethod and others
  const getErrorMessage = (
    limit: number,
    currency: string,
    includeFee: boolean
  ) => {
    if (isMobileMoney) {
      return (
        `The maximum transferrable amount ${
          includeFee ? "inclusive of Operator Fees" : ""
        } for ${transferMethodsInWords[transferMethod].replace(
          "_",
          " "
        )} is ${formatAmount(String(limit))} ${currency}.` || ""
      );
    } else {
      return (
        `The maximum transferrable amount for  ${transferMethodsInWords[
          transferMethod
        ].replace("_", " ")} is ${formatAmount(String(limit))} ${currency}.` ||
        ""
      );
    }
  };

  // Check and return the validation state
  const inclusiveOfFee = allowOperatorFee;
  const errorMessage = IsBelowMinPayinValue
    ? `You can't send less than 5 ${payinCurrency}`
    : getErrorMessage(
        formattedTransferLimitMax,
        isInOriginCurrency ? payinCurrency : payoutCurrency,
        inclusiveOfFee
      );
  const isPayin = IsBelowMinPayinValue ? true : isInOriginCurrency;
  const payinValue = Number(payinActualValue);
  const payoutValue = Number(payoutActualValue);
  const fee = handleValueIsNaN(operatorFee);
  const isError = IsBelowMinPayinValue
    ? true
    : isInOriginCurrency
    ? payinValue + (inclusiveOfFee ? fee : 0) > formattedTransferLimitMax
    : payoutValue + (inclusiveOfFee ? fee : 0) > formattedTransferLimitMax;
  const transferLimitMaxOut = isInOriginCurrency
    ? payinValue + fee > formattedTransferLimitMax
    : payoutValue + fee > formattedTransferLimitMax;

  // const operatorFeeCallout = calculateQuoteFees(
  //   fee,
  //   isPayinInputActive
  // ).operatorFeeCallout;

  //auto switch Off operator toggle if transferLimitMaxOut is true
  if (transferLimitMaxOut) {
    store.dispatch({
      type: TRANSFER,
      payload: {
        ...transfer,
        allowOperatorFee: false,
      },
    });
  }

  //update is isErrorQuoteInput on isError true
  // if (isError || !isError) {
  //   store.dispatch({
  //     type: TRANSFER,
  //     payload: {
  //       ...transfer,
  //       isErrorQuoteInput: isError ? true : false,
  //     },
  //   });
  // }

  return {
    fee,
    errorMessage,
    isPayin,
    isError,
    // operatorFeeCallout,
    transferLimitMaxOut,
  };
};

// Helper function to calculate the amount with precision to avoid rounding errors
export function calculatePayAmount(
  amount: number | string,
  exchangeRate: number,
  isPayout: boolean
): number {
  const amountToNumber = Number(amount);
  const result = isPayout
    ? amountToNumber / exchangeRate
    : amountToNumber * exchangeRate;

  const formatResult = Number(result.toFixed(2));
  return formatResult;
}

//------Quote Calculation---//
interface CalculationResult {
  operatorFeeCallout: string;
}

// Helper function to get the promotional discount value
function getPromoDiscountValue(
  promoType: string,
  promoDiscountValue: number
): number {
  return promoType === "FIXED_AMOUNT" || promoType === "PERCENTAGE"
    ? promoDiscountValue
    : 0;
}

// Helper function to get loyalty or referral discounts
function getLoyaltyOrReferralDiscount(user: any): number {
  return getRewardsValues(user).active ? getRewardsValues(user).bonus : 0;
}

// Helper function to calculate the total amount to pay considering all applicable discounts and fees
function calculateTotalToPay(
  baseAmount: number,
  operatorFee: number,
  promoDiscount: number,
  loyaltyDiscount: number
): number {
  return baseAmount + operatorFee - promoDiscount - loyaltyDiscount;
}

export function updateTotalPayinAndPayoutValues(
  serviceFee: number,
  serviceFeeInPayInvalue: number,
  isPayin: boolean,
  isInOriginCurrency: boolean
): CalculationResult {
  const transfer = store.getState().transfer;
  const user = store.getState().auth.user;
  const {
    allowOperatorFee,
    payinActualValue,
    payoutActualValue,
    transferMethod,
    exchangeRate,
    promoFreeOperatorFee,
    promoType,
    promoDiscountValue,
    promoRate,
  } = transfer || {};

  let payIn = Number(payinActualValue);
  let payOut = Number(payoutActualValue);
  let totalPayOut = Number(payoutActualValue);
  let operatorFeeCallout = "";

  const conditionalRate = promoType === "FIXED_RATE" ? promoRate : exchangeRate; //when calc totalToSend

  const operatorFee = (isTotalToPay: boolean): number => {
    if (promoType === "FREE_OPERATOR_FEE" && promoFreeOperatorFee) return 0;
    else if (isTotalToPay) return Number(serviceFeeInPayInvalue);
    else return Number(serviceFee);
  };

  const operatorFeeForCallout = formatNumberWithCommas(operatorFee(isPayin));

  const promoDiscount = getPromoDiscountValue(promoType, promoDiscountValue);
  const loyaltyDiscount = getLoyaltyOrReferralDiscount(user);

  // Scenario A: User types in PayIn input
  if (isPayin) {
    // Scenario A(i): operator fee is on
    if (allowOperatorFee) {
      operatorFeeCallout = `+${operatorFeeForCallout}`;
      // Calculate payOut based on payIn without fee deduction
      totalPayOut = calculatePayAmount(payIn, conditionalRate, false);
      payOut = calculatePayAmount(payIn, exchangeRate, false);
      // Dispatch updated state with correct payOut and totalToPay
      store.dispatch({
        type: TRANSFER,
        payload: {
          ...transfer,
          totalToPay: calculateTotalToPay(
            payIn,
            operatorFee(true),
            promoDiscount,
            loyaltyDiscount
          ),
          totalToSend: totalPayOut,
        },
      });
    }
    // Scenario A(ii): operator fee is off
    else {
      operatorFeeCallout = `-${operatorFeeForCallout}`;
      // Calculate payOut based on payIn after fee deduction
      payOut = calculatePayAmount(
        payIn - (!isInOriginCurrency ? 0 : operatorFee(false)),
        exchangeRate,
        false
      );
      totalPayOut = calculatePayAmount(
        payIn - (!isInOriginCurrency ? 0 : operatorFee(false)),
        conditionalRate,
        false
      );
      // Dispatch updated state with correct payOut and totalToPay
      store.dispatch({
        type: TRANSFER,
        payload: {
          ...transfer,
          totalToPay: calculateTotalToPay(
            payIn,
            0, // operator fee OFF
            promoDiscount,
            loyaltyDiscount
          ),
          totalToSend: totalPayOut,
        },
      });
    }
  }
  // Scenario B: User types in PayOut input
  else {
    // Scenario B(i): operator fee is on
    if (allowOperatorFee) {
      operatorFeeCallout = `+${operatorFeeForCallout}`;
      // Calculate payIn based on payOut without fee addition
      payIn = calculatePayAmount(payOut, exchangeRate, true);
      // Dispatch updated state with correct payIn and totalToPay
      store.dispatch({
        type: TRANSFER,
        payload: {
          ...transfer,
          totalToPay: calculateTotalToPay(
            payIn,
            operatorFee(true),
            promoDiscount,
            loyaltyDiscount
          ),
          totalToSend: payoutActualValue,
        },
      });
    }
    // Scenario B(ii): operator fee is off
    else {
      operatorFeeCallout = `-${operatorFeeForCallout}`;
      // Calculate payIn based on payOut after fee addition
      // If is mobile_money do not deduct a fee
      payIn =
        payOut / exchangeRate - (!isInOriginCurrency ? 0 : operatorFee(true));
      totalPayOut = !isInOriginCurrency
        ? payoutActualValue
        : payoutActualValue -
          calculatePayAmount(operatorFee(false), conditionalRate, false);
      // Dispatch updated state with correct payIn and totalToPay
      store.dispatch({
        type: TRANSFER,
        payload: {
          ...transfer,
          totalToPay: calculateTotalToPay(
            payinActualValue,
            0,
            promoDiscount,
            loyaltyDiscount
          ),
          totalToSend: totalPayOut,
        },
      });
    }
  }

  const defaultOperatorFeeCallout = ``;

  return {
    operatorFeeCallout:
      !isInOriginCurrency && !allowOperatorFee
        ? defaultOperatorFeeCallout
        : operatorFeeCallout,
  };
}

export const getRewardsValues = (user: any) => {
  const { Referral, Referrals } = user?.referral || {};
  const { Voucher, Vouchers } = user?.meta || {};
  const { values } = store.getState().appValues;
  const transfer = store.getState().transfer;
  const { payinActualValue } = transfer || {};

  const referralConstants = getAppValueDataByName(values.data, "settings");
  const loyaltyConstants = getAppValueDataByName(values.data, "loyaltyscheme");

  const minAmountAppllied = getAppValueDataByName(
    values.data,
    "referralscheme"
  )?.minReferralAmount;

  const isRewardApplicable =
    Number(payinActualValue) >= Number(minAmountAppllied);

  const rawUplineReferralBonus = referralConstants?.referredUserDiscountValue;
  const rawDownlineReferralBonus = referralConstants?.referrerDiscountValue;

  const uplineReferralBonus = isNaN(rawUplineReferralBonus)
    ? 0
    : Number(rawUplineReferralBonus);
  const downlineReferralBonus = isNaN(rawDownlineReferralBonus)
    ? 0
    : Number(rawDownlineReferralBonus);

  const isVoucherActive = user && isRewardApplicable && Voucher === "ACTIVE";
  const isNewBonusStateActive =
    user && isRewardApplicable && Referral === "ACTIVE";

  const referralsArray = Referrals && JSON.parse(Referrals);

  const rawVoucherBonus = loyaltyConstants?.voucherBonus;
  const voucherBonus = isNaN(rawVoucherBonus) ? 0 : Number(rawVoucherBonus);

  const isReferralHasUplineBonusAndIsActive =
    isNewBonusStateActive &&
    referralsArray?.some(
      (referral: any) =>
        Number(referral.Bonus) === uplineReferralBonus &&
        referral.ReferralBonus === "ACTIVE"
    );

  const bonusToUse = isNewBonusStateActive
    ? isReferralHasUplineBonusAndIsActive
      ? uplineReferralBonus
      : downlineReferralBonus
    : voucherBonus || 0;

  return {
    active: isNewBonusStateActive || isVoucherActive || false,
    bonus: bonusToUse,
    type: isNewBonusStateActive ? "Referral" : "Loyalty",
  };
};

export const getOperatorFeeDescription = (transferMethod: string): string => {
  const isMobileMoney =
    transferMethodsInWords[transferMethod] === "mobile_money";
  if (isMobileMoney) {
    return "SBremit transfers are free. However, our partners have a flat rate charge for withdrawal, which will be deducted from what your recipient will receive. Would you like to pay it additionally?";
  } else {
    return "SBremit transfers are free. However, the banks have a flat rate charge for transfers made, and this fee must be paid. Would you like to pay it additionally, or would you like it subtracted from what you’re already sending?";
  }
};
