import _env from "env";
import { useEffect, useRef, useState } from "react";
import { resources } from "../../../util/constants";
import { settings } from "../../../util/settings";

import { useSelector } from "react-redux";
import { getDateTimeNowInYYYY_MM_DD__HH_MM_SS_FromServer } from "redux/actions/actions";
import sjcl from "sjcl";
import { getDateTimeNowInYYYY_MM_DD__HH_MM_SS } from "../../../util/util";
import useBillingDetails from "./trustPaymentHelper";
require("dotenv").config();

interface IPaymentRedirect {
  stprofile?: string;
  currencyiso3a: string;
  mainamount: number;
  transactionId: string;
  transferId: string;
  enabled: boolean;
  setEnabled: Function;
  credentialsonfile: "1" | "0"; //This must be set to “1”, to indicate the customer agreed for the payment credentials to be stored for future transactions.
}

const PaymentRedirect = ({
  stprofile = "default",
  currencyiso3a,
  mainamount,
  transactionId,
  transferId,
  enabled,
  setEnabled,
  credentialsonfile,
}: IPaymentRedirect) => {
  const user = useSelector((state: any) => state.auth.user);
  const formRef = useRef<HTMLFormElement>(null);
  const [utcDateTime, setUtcDateTime] = useState(
    getDateTimeNowInYYYY_MM_DD__HH_MM_SS()
  );

  useEffect(() => {
    getDateTimeNowInYYYY_MM_DD__HH_MM_SS_FromServer(setUtcDateTime);
  }, []);

  const continueToPayment = (e: any): any => {
    e.target.mainamount.value = mainamount;
    e.target.currencyiso3a.value = currencyiso3a;
    setEnabled();
    return true;
  };

  useEffect(() => {
    //manually trigger form submit and action
    if (enabled && formRef.current) {
      formRef.current.dispatchEvent(
        new Event("submit", { bubbles: true, cancelable: true })
      );
      // const formData = new FormData(formRef.current);
      // const data = Object.fromEntries(formData.entries());
      // console.log("Form Data: ", data);
      formRef.current.submit();
    }
  }, [enabled]);

  const stdefaultprofile = "st_paymentcardonly";
  const orderReference = transactionId;
  const {
    billingemail,
    billingfirstname,
    billinglastname,
    billingdob,
    billingpremise,
    billingtown,
    billingpostcode,
    billingcountryiso2a,
    billingstreet,
    billingcounty,
    customeraccountnumber,
  } = useBillingDetails(user);

  const password = process.env.REACT_APP_TRUST_SITE_PASSWORD;
  const siteSecurityTimestamp = utcDateTime;
  const version = 2;
  const accountfunding = "1";
  const transactiontypeindicator = "F07";
  const ruleIdentifier1 = "STR-1";
  const ruleIdentifier2 = "STR-2";
  const ruleIdentifier3 = "STR-3";
  const ruleIdentifier4 = "STR-4";
  const ruleIdentifier5 = "STR-5";
  const strequiredfields = "nameoncard";
  const stextraurlnotifyfields1 = "nameoncard";
  const stextraurlnotifyfields2 = "issuercountryiso2a";
  const stextraurlnotifyfields3 = "currencyiso3a";
  const stextraurlnotifyfields4 = "mainamount";
  const stextraurlnotifyfields5 = "billingfirstname";
  const stextraurlnotifyfields6 = "billingmiddlename";
  const stextraurlnotifyfields7 = "billinglastname";
  const stextraurlnotifyfields8 = "maskedpan";
  const stextraurlnotifyfields9 = "requesttypedescription";
  const stextraurlnotifyfields10 = "authcode";
  const stextraurlredirectfields = "nameoncard";
  const successfulRedirectURL = `${_env.APP_HOST}/transfer-completed/${transferId}?payment_type=trust_payment`;

  //Compute stringToHash
  let stringToHash = currencyiso3a ?? "";
  stringToHash += mainamount ?? "";
  stringToHash += settings.TRUST_PAYMENT_SITE_REFERENCE ?? "";
  stringToHash += strequiredfields;
  stringToHash += version ?? "";
  stringToHash += stprofile ?? "";
  stringToHash += ruleIdentifier1 ?? "";
  stringToHash += ruleIdentifier2 ?? "";
  stringToHash += ruleIdentifier3 ?? "";
  stringToHash += ruleIdentifier4 ?? "";
  stringToHash += ruleIdentifier5 ?? "";
  stringToHash += stdefaultprofile;
  stringToHash += successfulRedirectURL;
  stringToHash += settings.TRUST_NOTIFICATION_WEBHOOK_URL ?? "";
  stringToHash += settings.TRUST_NOTIFICATION_WEBHOOK_URL ?? "";
  stringToHash += stextraurlnotifyfields1;
  stringToHash += stextraurlnotifyfields2;
  stringToHash += stextraurlnotifyfields3;
  stringToHash += stextraurlnotifyfields4;
  stringToHash += stextraurlnotifyfields5;
  stringToHash += stextraurlnotifyfields6;
  stringToHash += stextraurlnotifyfields7;
  stringToHash += stextraurlnotifyfields8;
  stringToHash += stextraurlnotifyfields9;
  stringToHash += stextraurlnotifyfields10;
  stringToHash += stextraurlredirectfields;
  stringToHash += credentialsonfile;

  stringToHash += siteSecurityTimestamp;
  stringToHash += password ?? "";
  const siteSecurityHash =
    "h" + sjcl.codec.hex.fromBits(sjcl.hash.sha256.hash(stringToHash));

  return (
    <span>
      <form
        ref={formRef}
        method="POST"
        action={resources.TRUST_PAYMENT_URL}
        onSubmit={(e) => continueToPayment(e)}
      >
        {/* <!-- sitereference; stprofile; orderreference; sitesecurity and version information --> */}
        <input
          type="hidden"
          name="sitereference"
          value={settings.TRUST_PAYMENT_SITE_REFERENCE}
        />
        <input type="hidden" name="stprofile" value={stprofile} />
        <input type="hidden" name="version" value={version} />
        <input type="hidden" name="orderreference" value={orderReference} />
        <input type="hidden" name="sitesecurity" value={siteSecurityHash} />
        {/* <!-- End of sitereference; stprofile; orderreference; sitesecurity and version information --> */}

        {/* <!-- Account funding flagging --> */}
        <input type="hidden" name="accountfunding" value={accountfunding} />
        <input
          type="hidden"
          name="transactiontypeindicator"
          value={transactiontypeindicator}
        />
        {/* <!-- End of Account funding flagging --> */}

        {/* <!-- Sender information --> */}
        <input type="hidden" name="billingfirstname" value={billingfirstname} />
        <input type="hidden" name="billinglastname" value={billinglastname} />
        <input type="hidden" name="billingemail" value={billingemail} />

        <input type="hidden" name="billingdob" value={billingdob} />
        <input type="hidden" name="billingpremise" value={billingpremise} />
        <input type="hidden" name="billingstreet" value={billingstreet} />
        <input type="hidden" name="billingtown" value={billingtown} />
        <input type="hidden" name="billingcounty" value={billingcounty} />
        <input type="hidden" name="billingpostcode" value={billingpostcode} />
        <input
          type="hidden"
          name="billingcountryiso2a"
          value={billingcountryiso2a}
        />
        {/* <!-- End of sender information --> */}

        {/* <!-- Recipient information --> */}
        <input
          type="hidden"
          name="customerfirstname"
          value={billingfirstname}
        />
        <input type="hidden" name="customerlastname" value={billinglastname} />
        <input
          type="hidden"
          name="customercountryiso2a"
          value={billingcountryiso2a}
        />
        <input
          type="hidden"
          name="customeraccountnumber"
          value={customeraccountnumber}
        />
        <input type="hidden" name="customeraccountnumbertype" value="ACCOUNT" />
        {/* <!-- End of recipient information --> */}

        {/* <!-- Amount and currency information --> */}
        <input type="hidden" name="mainamount" value={mainamount} />
        <input type="hidden" name="currencyiso3a" value={currencyiso3a} />
        {/* <!-- End of Amount and currency information --> */}

        <input type="hidden" name="stdefaultprofile" value={stdefaultprofile} />
        <input type="hidden" name="strequiredfields" value={strequiredfields} />

        {/* Rule Identifier fields */}
        <input type="hidden" name="ruleidentifier" value={ruleIdentifier1} />
        <input type="hidden" name="ruleidentifier" value={ruleIdentifier2} />
        <input type="hidden" name="ruleidentifier" value={ruleIdentifier3} />
        <input type="hidden" name="ruleidentifier" value={ruleIdentifier4} />
        <input type="hidden" name="ruleidentifier" value={ruleIdentifier5} />
        {/* ---- */}
        <input
          type="hidden"
          name="successfulurlnotification"
          value={settings.TRUST_NOTIFICATION_WEBHOOK_URL}
        />
        <input
          type="hidden"
          name="successfulurlredirect"
          value={successfulRedirectURL}
        />
        <input
          type="hidden"
          name="allurlnotification"
          value={settings.TRUST_NOTIFICATION_WEBHOOK_URL}
        />
        {/* End of rule Identifier fields */}

        {/* Store Credentials : Configuration for storing payment credentials for future transactions */}
        <input
          type="hidden"
          name="credentialsonfile"
          value={credentialsonfile}
        />
        {/* End of Store Credentials  */}

        {/* Custom fields for custom notification */}
        <input
          type="hidden"
          name="stextraurlnotifyfields"
          value={stextraurlnotifyfields1}
        />
        <input
          type="hidden"
          name="stextraurlnotifyfields"
          value={stextraurlnotifyfields2}
        />
        <input
          type="hidden"
          name="stextraurlnotifyfields"
          value={stextraurlnotifyfields3}
        />
        <input
          type="hidden"
          name="stextraurlnotifyfields"
          value={stextraurlnotifyfields4}
        />
        <input
          type="hidden"
          name="stextraurlnotifyfields"
          value={stextraurlnotifyfields5}
        />
        <input
          type="hidden"
          name="stextraurlnotifyfields"
          value={stextraurlnotifyfields6}
        />
        <input
          type="hidden"
          name="stextraurlnotifyfields"
          value={stextraurlnotifyfields7}
        />
        <input
          type="hidden"
          name="stextraurlnotifyfields"
          value={stextraurlnotifyfields8}
        />

        <input
          type="hidden"
          name="stextraurlnotifyfields"
          value={stextraurlnotifyfields9}
        />
        <input
          type="hidden"
          name="stextraurlnotifyfields"
          value={stextraurlnotifyfields10}
        />

        <input
          type="hidden"
          name="stextraurlredirectfields"
          value={stextraurlredirectfields}
        />

        <input
          type="hidden"
          name="sitesecuritytimestamp"
          value={siteSecurityTimestamp}
        />
        {/*End of custom fields for custom notification */}
      </form>
    </span>
  );
};

export default PaymentRedirect;
